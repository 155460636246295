import React, { useContext } from 'react'
import { navigate, Link } from 'gatsby'

import './admin.scss'

import { Context } from '../Context'
import Layout from '../components/Layout'
import { isBrowser, hasRole } from '../components/Auth'

const AdminPage = () => {
  const { isAuth } = useContext(Context)
  if (!isAuth) {
    if (isBrowser()) {
      navigate('/dashboard/')
    }
    return <Layout withoutHeader>Cargando...</Layout>
  }

  if (!hasRole(1)) {
    if (isBrowser()) {
      navigate('/dashboard/')
    }
  }

  return (
    <Layout className='page-admin'>
      <div style={{ color: 'white', margin: '2rem 4rem' }}>
        <h1>Enlaces de administración</h1>

        <ul>
          <li>
            <Link to='/dashboard/'>Dashboard</Link>
          </li>
          <li>
            <Link to='/permissions/'>Permisos</Link>
          </li>
          <li>
            <Link to='/users/'>Usuarios</Link>
          </li>
          <li>
            <Link to='/remissions/'>Remisiones</Link>
          </li>
          <li>
            <Link to='/reports/'>Reportes</Link>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default AdminPage
